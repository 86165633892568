import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../../store/app.reducer';
import * as WorkoutActions from '../../../workouts/store/workouts.actions';
import {MatTableDataSource} from '@angular/material';
import {EventModel} from '../../../../../shared/models/calendar/eventModel';

@Component({
  selector: 'app-calendar-workouts',
  templateUrl: './calendar-workouts.component.html',
  styleUrls: ['./calendar-workouts.component.scss']
})
export class CalendarWorkoutsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'actions'];
  eventReview: EventModel = null;
  students;
  error;
  isLoading = false;
  constructor(
    private store: Store<AppState.AppState>
  ) { }

  ngOnInit() {
    this.store.select('calendar').subscribe(data => {
      this.isLoading = data.loading;
      this.error = data.error;
      this.eventReview = data.eventReview;
      if (this.eventReview && this.eventReview.students) { this.students = new MatTableDataSource(this.eventReview.students);}
    });
    this.store.select('workouts').subscribe(data => {
      this.isLoading = data.loading;
      this.error = data.error;
    });
  }
  review(element) {
    this.store.dispatch(new WorkoutActions.CreateTrainerReviewRequestStart({
        studentId: element.id,
        id: element.recordings[0].id
      })
    );
  }
}
