import {AfterViewInit, Component, OnInit} from '@angular/core';
import {User} from '../../../../../shared/models/user/user.model';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../../store/app.reducer';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../../core/authentication/authentication.service';
import {MatTableDataSource} from '@angular/material';
import * as CalendarActions from '../../store/calendar.actions';
import {CalendarService} from '../../../../../core/services/calendar.service';

@Component({
  selector: 'app-calendar-private',
  templateUrl: './calendar-private.component.html',
  styleUrls: ['./calendar-private.component.scss']
})
export class CalendarPrivateComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['title', 'description', 'date', 'started', 'finished', 'actions'];
  events;
  user: User = null;
  isLoading = false;
  isStudent = false;
  homePage = true;
  hasWebcam = false;
  hasMic = false;
  constructor(
    private store: Store<AppState.AppState>,
    private router: Router,
    private authSrv: AuthenticationService,
    private calendarService: CalendarService
  ) {}

  ngOnInit() {
    this.store.dispatch(new CalendarActions.Home({home: true}));
    this.store.select('calendar').subscribe(calendarData => {
      this.isLoading = calendarData.loading;
      this.events = new MatTableDataSource(calendarData.events);
      this.homePage = calendarData.homePage;
    });
    this.store.select('authentication').subscribe(authState => {
      this.user = authState.user;
      this.isStudent = this.user ? this.authSrv.isStudent(authState.user.roles) : true;
    });
  }
  ngAfterViewInit() {
    this.calendarService.detectWebcamAndMic((hasWebcam, hasMic) => {
      this.hasWebcam = hasWebcam;
      this.hasMic = hasMic;
    });
  }
  create() {
    this.store.dispatch(new CalendarActions.Home({home: false}));
    this.router.navigate(['calendar/private/create']);
  }
  edit(calendarEvent) {
    this.store.dispatch(new CalendarActions.Home({home: false}));
    this.router.navigate(['calendar/private/edit', calendarEvent.id]);
  }
  view(calendarEvent) {
    this.store.dispatch(new CalendarActions.Home({home: false}));
    this.router.navigate(['calendar/private/view', calendarEvent.id]);
  }
  viewWorkouts(calendarEvent) {
    this.store.dispatch(new CalendarActions.GetTrainingWorkoutsStart({id: calendarEvent.id}));
    this.router.navigate(['calendar/workouts']);
  }
  delete(calendarEvent) {
    this.store.dispatch(new CalendarActions.DeleteStart({id: calendarEvent.id}));
  }
  open(calendarEvent) {
    this.router.navigate(['training', calendarEvent.id]);
  }
}
