import {NgModule} from '@angular/core';
import {AccountComponent} from './account.component';
import {AuthenticationGuard} from '../../../core/authentication/authentication.guard';
import {AccountUpdateComponent} from './components/account-update/account-update.component';
import {RouterModule} from '@angular/router';
import {AccountViewComponent} from './components/account-view/account-view.component';
import {AccountResolve} from '../../../core/resolvers/account.resolve';

const routes = [
  {
    path: '',
    component: AccountComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'view'},
      {path: 'view', component: AccountViewComponent, resolve: {data: AccountResolve}},
      {path: 'update', component: AccountUpdateComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AccountRoutingModule {}
