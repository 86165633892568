import { Component, OnInit } from '@angular/core';
import {EventModel} from '../../../../../shared/models/calendar/eventModel';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../../store/app.reducer';
import {ActivatedRoute, Router} from '@angular/router';
import * as Moment from 'moment';
import * as CalendarActions from '../../store/calendar.actions';
import {UserProfile} from '../../../../../shared/models/user/user-profile';
import {CalendarService} from '../../../../../core/services/calendar.service';

@Component({
  selector: 'app-calendar-edit',
  templateUrl: './calendar-edit.component.html',
  styleUrls: ['./calendar-edit.component.scss']
})
export class CalendarEditComponent implements OnInit {
  isLoading = false;
  error = null;
  id: string = null;
  calendarEvent: EventModel = null;
  editFormGroup: FormGroup;
  hoursArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutesArray = ['00', '15', '30', '45'];
  usersArray: UserProfile[] = [];
  constructor(
    private store: Store<AppState.AppState>,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private calendarServ: CalendarService
  ) { }
  get date() { return new Date(this.calendarEvent.start + ' UTC'); }
  get hours() {
    const hours = new Date(this.calendarEvent.start + ' UTC').getHours().toString();
    return hours !== '0' ? hours : '00';
  }
  get minutes() {
    const minutes = new Date(this.calendarEvent.start + ' UTC').getMinutes().toString();
    return minutes !== '0' ? minutes : '00';
  }
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.store.select('calendar').subscribe(calendarState => {
      this.calendarEvent = calendarState.events.find(e => e.id === this.id);
      this.isLoading = calendarState.loading;
      this.error = calendarState.error;
      this.usersArray = calendarState.users;
    });
    this._initForm();
  }
  private _initForm() {
    this.editFormGroup = this.formBuilder.group({
      date: [this.date, Validators.required],
      title: [this.calendarEvent.title, Validators.required],
      description: [this.calendarEvent.description, Validators.required],
      students: [this.calendarEvent.students.map(u => u.id), Validators.required],
      public: [!this.calendarEvent.isPrivate],
      hours: [this.hours, Validators.required],
      minutes: [this.minutes, Validators.required]
    });
    if (!this.calendarEvent.isPrivate) {
      this.editFormGroup.get('students').disable();
    }
  }
  createCalendarEvent(): EventModel {
    const hours = +this.editFormGroup.get('hours').value;
    const minutes = +this.editFormGroup.get('minutes').value;
    const date = this.editFormGroup.get('date').value;
    date.setHours(hours);
    date.setMinutes(minutes);
    const startDate = date.toISOString();
    date.setHours(hours + 1)
    const endDate = date.toISOString();
    return {
      id: this.id,
      start: startDate,
      end: endDate,
      started: this.calendarEvent.started,
      public: !!this.editFormGroup.get('public').value,
      description: this.editFormGroup.get('description').value,
      title: this.editFormGroup.get('title').value,
      students: this.editFormGroup.get('public').value ? [] : this.editFormGroup.get('students').value,
    };
  }
  back() {
    this.store.dispatch(new CalendarActions.Home({home: true}));
    this.calendarServ.navigateBack();
  }
  submit($event) {
    $event.preventDefault();
    const updatedEvent = this.createCalendarEvent();
    this.store.dispatch(new CalendarActions.UpdateEventStart({
      event: updatedEvent
    }));
  }
  toggleStudents($event) {
    if ($event.checked) {
      this.editFormGroup.get('students').disable();
      this.editFormGroup.get('students').clearValidators();
    } else {
      this.editFormGroup.get('students').enable();
      this.editFormGroup.get('students').setValidators([Validators.required]);
    }
  }
}
