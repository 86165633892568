import * as ProfileActions from './profile.actions';
import {ProfileModel} from '../../../../shared/models/user/profile.model';

export interface State {
  profile: ProfileModel;
  userProfiles: ProfileModel[];
  error: {
    errorMessage: string,
    errors: any
  };
  success: string;
  loading: boolean;
}

const initialState: State = {
  profile: null,
  userProfiles: [],
  error: {
    errorMessage: null,
    errors: null
  },
  success: null,
  loading: false
}
export function profileReducer(state: State = initialState, action: ProfileActions.ProfileActions) {
  switch (action.type) {
    case ProfileActions.GET_PROFILE_START:
    case ProfileActions.UPDATE_PROFILE_START:
      return {
        ...state,
        loading: true,
        success: null,
        error: null
      }
    case ProfileActions.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.profile
      }
    case ProfileActions.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.profile
      }
    case ProfileActions.PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        }
      }
    case ProfileActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case ProfileActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    case ProfileActions.GET_ALL_PROFILES_START:
      return {
        ...state,
        loading: true,
        userProfiles: []
      }
    case ProfileActions.GET_ALL_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfiles: action.payload.profiles
      }
    default:
      return state;
  }
}
