import * as CalendarActions from './calendar.actions';
import {EventModel} from '../../../../shared/models/calendar/eventModel';
import {UserProfile} from '../../../../shared/models/user/user-profile';

export interface State {
  events: EventModel[];
  users: UserProfile[];
  eventReview: EventModel;
  error: {
    errorMessage: string,
    errors: any
  };
  success: string;
  loading: boolean;
  homePage: boolean;
  startedEvents: string[];
}

const initialState: State = {
  events: [],
  users: [],
  eventReview: null,
  error: {
    errorMessage: null,
    errors: null
  },
  success: null,
  loading: false,
  homePage: true,
  startedEvents: []
}

export function calendarReducer(state: State = initialState, action: CalendarActions.CalendarActions) {
  switch (action.type) {
    case CalendarActions.CREATE_EVENT_START:
    case CalendarActions.GET_EVENT_START:
    case CalendarActions.GET_PUBLIC_EVENT_START:
    case CalendarActions.UPDATE_EVENT_START:
    case CalendarActions.DELETE_START:
    case CalendarActions.GET_TRAINING_WORKOUTS_START:
      return {
        ...state,
        error: null,
        success: null,
        loading: true
      }
    case CalendarActions.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        events: [...state.events, action.payload.event],
        loading: false,
        success: action.payload.message,
        error: null
      }
    case CalendarActions.GET_EVENT_SUCCESS:
      return {
        ...state,
        events: action.payload.events,
        loading: false,
        error: null
      }
    case CalendarActions.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        loading: false,
        error: null
      }
    case CalendarActions.UPDATE_EVENT_SUCCESS:
      const calendarRecord = {
        ...state.events.find(c => c.id === action.payload.event.id),
        ...action.payload.event
      };
      const updateIndex = state.events.findIndex(c => c.id === action.payload.event.id);
      const updatedCalendars = [...state.events];
      updatedCalendars[updateIndex] = calendarRecord;
      return {
        ...state,
        events: updatedCalendars,
        loading: false,
        success: action.payload.message,
        error: null
      }
    case CalendarActions.DELETE_SUCCESS:
      const deleteIndex = state.events.findIndex(c => c.id === action.payload.id);
      state.events.splice(deleteIndex, 1);
      return {
        ...state,
        events: state.events,
        loading: false,
        success: action.payload.message,
        error: null
      }
    case CalendarActions.CALENDAR_FAIL:
      return {
        ...state,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
        loading: false
      }
    case CalendarActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case CalendarActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    case CalendarActions.HOME:
      return {
        ...state,
        homePage: action.payload.home
      }
    case CalendarActions.START_EVENT_SUCCESS:
      const findStartIndex = state.events.findIndex(e => e.id === action.payload.id);
      if (findStartIndex && state.events[findStartIndex]) {
        state.events[findStartIndex].started = true;
        state.startedEvents.push(action.payload.id);
      }
      return {
        ...state,
        events: state.events,
        startedEvents: state.startedEvents,
        success: action.payload.message,
        error: null
      };
    case CalendarActions.EVENT_STARTED:
      const findIndex = state.events.findIndex(e => e.id === action.payload.id);
      if (findIndex >= 0 && state.events[findIndex]) {
        state.events[findIndex].started = true;
        if (!state.startedEvents.includes(action.payload.id)) {
          state.startedEvents.push(action.payload.id);
        }
      }
      return {
        ...state,
        events: state.events,
        startedEvents: state.startedEvents
      }
    case CalendarActions.GET_TRAINING_WORKOUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        eventReview: action.payload.eventReview,
        error: null
      }
    case CalendarActions.CLEAR_STORE:
      return initialState;
    default:
      return state;
  }
}
