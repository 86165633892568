import {Resolve} from '@angular/router';
import {MessagesService} from '../services/messages.service';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class MessagesResolve implements Resolve<any> {
  constructor(
    private messagesServ: MessagesService
  ) {}
  resolve() {
    this.messagesServ.getData();
    return null;
  }
}
