import { Component, OnInit } from '@angular/core';
import {EventModel} from '../../../../../shared/models/calendar/eventModel';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../../store/app.reducer';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as CalendarActions from '../../store/calendar.actions';
import {CalendarService} from '../../../../../core/services/calendar.service';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss']
})
export class CalendarViewComponent implements OnInit {
  isLoading = false;
  error = null;
  calendarEvent: EventModel = null;
  id: string = null;
  userId: string = null;
  hasWebcam: false;
  hasMic: false;
  constructor(
    private store: Store<AppState.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private calendarServ: CalendarService
  ) {
    calendarServ.detectWebcamAndMic((hasWebcam, hasMic) => {
      this.hasWebcam = hasWebcam;
      this.hasMic = hasMic;
    });
  }

  ngOnInit() {
    this.router.events.subscribe(events => {
      if (events instanceof NavigationEnd) {
        this.id = this.route.snapshot.paramMap.get('id');
      }
    });
    this.id = this.route.snapshot.paramMap.get('id');
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.userId = userData.id;
    this.store.select('calendar').subscribe(calendarState => {
      this.calendarEvent = calendarState.events.find(e => e.id === this.id);
      this.isLoading = calendarState.loading;
      this.error = calendarState.error;
    });
  }
  close() {
    this.store.dispatch(new CalendarActions.Home({home: true}));
    this.calendarServ.navigateBack();
  }
  open() {
    this.store.dispatch(new CalendarActions.Home({home: true}));
    this.router.navigate(['training', this.calendarEvent.id]);
  }
}
