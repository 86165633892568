import * as fromAuthentication from '../authentication/store/authentication.reducer';
import * as fromCalendar from '../pages/shared-pages/calendar/store/calendar.reducer';
import * as fromWorkouts from '../pages/shared-pages/workouts/store/workouts.reducer';
import * as fromAccount from '../pages/shared-pages/account/store/account.reducer';
import * as fromProfile from '../pages/shared-pages/profile/store/profile.reducer';
import * as fromTraining from '../pages/shared-pages/training/store/training.reducer';
import * as fromMessages from '../pages/shared-pages/messages/store/messages.reducer';

import {ActionReducerMap} from '@ngrx/store';

export interface AppState {
  authentication: fromAuthentication.State;
  calendar: fromCalendar.State;
  workouts: fromWorkouts.State;
  account: fromAccount.State;
  profile: fromProfile.State;
  training: fromTraining.State;
  messages: fromMessages.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  authentication: fromAuthentication.authenticationReducer,
  calendar: fromCalendar.calendarReducer,
  workouts: fromWorkouts.workoutsReducer,
  account: fromAccount.accountReducer,
  profile: fromProfile.profileReducer,
  training: fromTraining.trainingReducer,
  messages: fromMessages.messagesReducer
};
