import {Roles} from '../../enums/roles.enum';

export class User {
  firstName = '';
  lastName = '';
  password = '';
  constructor(
    public email: string = null,
    public roles: Roles[],
    private _token?: string,
    private _tokenExpirationDate?: Date,
    private _id?: string,
    private _firstName?: string,
    private _lastName?: string,
    private _password?: string
  ) {
    this.firstName = _firstName;
    this.lastName = _lastName;
    this.password = _password;
  }
  get token() {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }
    return this._token;
  }
  get id() { return this._id; }
  get fullName() { return this._firstName + ' ' + this._lastName; }
}
