import { NgModule } from '@angular/core';
import { TrainerComponent } from './trainer.component';
import {SharedModule} from '../../../shared/shared.module';
import {TrainerRoutingModule} from './trainer-routing.module';

@NgModule({
  declarations: [TrainerComponent],
  imports: [
    SharedModule, TrainerRoutingModule
  ]
})
export class TrainerModule { }
