import {Action} from '@ngrx/store';

export const CLOSE_EVENT_START = '[Calendar] Close event start';
export const CLOSE_EVENT_SUCCESS = '[Calendar] Close event success';

export class CloseEventStart implements Action {
  readonly type = CLOSE_EVENT_START;
  constructor() {}
}
export class CloseEventSuccess implements Action {
  readonly type = CLOSE_EVENT_SUCCESS;
  constructor() {}
}

export type TrainingActions = CloseEventStart | CloseEventSuccess;
