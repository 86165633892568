import * as MessagesActions from './messages.actions';
import {UserProfile} from '../../../../shared/models/user/user-profile';
import {MessageModel} from '../../../../shared/models/messages/message.model';

export interface State {
  latest: UserProfile[];
  messages: MessageModel[];
  currentUser: string;
  newMessage: {
    received: boolean;
    fromUserId: string;
  };
  error: {
    errorMessage: string,
    errors: any
  };
  loading: boolean;
}

const initialState = {
  latest: [],
  messages: [],
  currentUser: null,
  newMessage: {
    received: false,
    fromUserId: null,
  },
  error: {
    errorMessage: null,
    errors: null
  },
  loading: false
}

export function messagesReducer(state: State = initialState, action: MessagesActions.MessagesActions) {
  switch (action.type) {
    case MessagesActions.SEND_MESSAGE_START:
      return {
        ...state,
        error: {
          errorMessage: null,
          errors: null
        }
      }
    case MessagesActions.GET_MESSAGES_START:
      let received = state.newMessage.received;
      if ((action.payload.id === state.newMessage.fromUserId) && state.newMessage.received) {
        received = false;
      }
      const fromUserId = action.payload.id === state.newMessage.fromUserId ? null : state.newMessage.fromUserId;
      return {
        ...state,
        loading: true,
        currentUser: action.payload.id,
        error: {
          errorMessage: null,
          errors: null
        },
        messages: [],
        newMessage: {
          received,
          fromUserId
        }
      }
    case MessagesActions.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.payload.messages
      }
    case MessagesActions.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: [...state.messages, action.payload.message]
      }
    case MessagesActions.GET_LATEST_SUCCESS:
      return {
        ...state,
        loading: false,
        latest: action.payload.latest
      }
    case MessagesActions.MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
        currentUser: null
      }
    case MessagesActions.CLEAR_ERROR:
      return {
        ...state,
        error: {
          errorMessage: null,
          errors: null
        }
      }
    case MessagesActions.RECEIVED_MESSAGE:
      let receivedNotification = true;
      let fromUserIdNotification = action.payload.senderId;
      if (action.payload.senderId === state.currentUser) {
        const message = state.messages.find(m => m.id === action.payload.message.id);
        if (!message) {
          state.messages = [...state.messages, action.payload.message];
        }
        receivedNotification = false;
        fromUserIdNotification = null;
      }

      return {
        ...state,
        messages: state.messages,
        newMessage: {
          received: receivedNotification,
          fromUserId: fromUserIdNotification
        }
      }
    case MessagesActions.CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null
      }
    case MessagesActions.CLEAR_STORE:
      return initialState;
    default:
      return state;
  }
}
