import {Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserService} from '../services/user.service';

@Injectable({providedIn: 'root'})
export class AccountResolve implements Resolve<any> {
  constructor(
    private userServ: UserService
  ) {}
  resolve() {
    this.userServ.getData();
    return null;
  }
}
