import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import * as MessagesActions from './messages.actions';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {BasicResponseModel} from '../../../../shared/models/basic-response.model';
import {environment} from '../../../../../environments/environment';
import {of} from 'rxjs';
import {MatSnackBar} from '@angular/material';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../store/app.reducer';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error && errorRes.error.message) {
    errorMessage = errorRes.error.message;
  } else if (errorRes.error && errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message ? errorRes.error.errors.message : 'An error occurred!';
    errors = errorRes.error.errors;
  }
  return of(new MessagesActions.MessagesFail({errorMessage, errors}));
};

@Injectable()
export class MessagesEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private store: Store<AppState.AppState>
  ) {}
  @Effect()
  getMessagesStart = this.actions$.pipe(
    ofType(MessagesActions.GET_MESSAGES_START),
    switchMap((action: MessagesActions.GetMessagesStart) => {
      return this.http.get<BasicResponseModel>(
        environment.baseApiUrl + 'messages/get/' + action.payload.id
      ).pipe(
        map(
          resData => {
            return new MessagesActions.GetMessagesSuccess({messages: resData.success.data});
          }
        ),
        catchError(
          errorRes => {
            return handleError(errorRes);
          }
        )
      );
    })
  );
  @Effect()
  getLatestStart = this.actions$.pipe(
    ofType(MessagesActions.GET_LATEST_START),
    switchMap((action: MessagesActions.GetLatestStart) => {
      return this.http.get<BasicResponseModel>(
        environment.baseApiUrl + 'messages/get/latest'
      ).pipe(
        map(
          resData => {
            return new MessagesActions.GetLatestSuccess({latest: resData.success.data});
          }
        ),
        catchError(
          errorRes => {
            return handleError(errorRes);
          }
        )
      );
    })
  );
  @Effect()
  sendMessageStart = this.actions$.pipe(
    ofType(MessagesActions.SEND_MESSAGE_START),
    switchMap((action: MessagesActions.SendMessageStart) => {
      return this.http.post<BasicResponseModel>(
        environment.baseApiUrl + 'messages/create',
        {
          message: action.payload.message,
          recipientId: action.payload.recipientId
        }
      ).pipe(
        map(
          resData => {
            return new MessagesActions.SendMessageSuccess({message: resData.success.data});
          }
        ),
        catchError(
          errorRes => {
            return handleError(errorRes);
          }
        )
      );
    })
  );
  @Effect({dispatch: false})
  messageFail = this.actions$.pipe(
    ofType(MessagesActions.MESSAGES_FAIL),
    tap((action: MessagesActions.MessagesFail) => {
      this.snackBar.open(action.payload.errorMessage, 'Close', {duration: 3000});
    })
  );
  @Effect({dispatch: false})
  receivedMessage = this.actions$.pipe(
    ofType(
      MessagesActions.RECEIVED_MESSAGE
    ),
    map((action: MessagesActions.ReceivedMessage) => action.payload),
    withLatestFrom(this.store),
    map(([action, store]) => {
      if (store.messages.currentUser !== action.senderId) {
        this.snackBar.open('You have received a new message!', 'Close', { duration: 3000 });
      }
    })
  );
}
