import {Resolve} from '@angular/router';
import {AuthenticationService} from '../authentication/authentication.service';
import {Injectable} from '@angular/core';
import {CalendarService} from '../services/calendar.service';
import {MessagesService} from '../services/messages.service';

@Injectable({providedIn: 'root'})
export class RootResolve implements Resolve<any> {
  constructor(
    private authServ: AuthenticationService,
    private calendarServ: CalendarService,
    private messagesServ: MessagesService
  ) {}
  resolve() {
    this.authServ.checkStoreUser();
    this.calendarServ.getData();
    this.messagesServ.subscribeToMessagesBroadCast();
    return null;
  }
}
