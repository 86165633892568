import {Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {WorkoutsService} from '../services/workouts.service';

@Injectable({providedIn: 'root'})
export class WorkoutsResolve implements Resolve<any> {
  constructor(
    private workoutsServ: WorkoutsService
  ) {}
  resolve() {
    this.workoutsServ.getData();
    return null;
  }
}
