import * as AccountActions from './account.actions';
import {UserProfile} from '../../../../shared/models/user/user-profile';

export interface State {
  profile: UserProfile;
  error: {
    errorMessage: string,
    errors: any
  };
  success: string;
  loading: boolean;
}

const initialState: State = {
  profile: null,
  error: {
    errorMessage: null,
    errors: null
  },
  success: null,
  loading: false
}

export function accountReducer(state: State = initialState, action: AccountActions.AccountActions) {
  switch (action.type) {
    case AccountActions.UPDATE_ACCOUNT_START:
    case AccountActions.GET_ACCOUNT_START:
      return {
        ...state,
        profile: null,
        loading: true,
        error: null,
        success: null
      }
    case AccountActions.GET_ACCOUNT_SUCCESSS:
      return {
        ...state,
        profile: action.payload.profile,
        loading: false,
        error: null
      }
    case AccountActions.UPDATE_ACCOUNT_SUCCESSS:
      return {
        ...state,
        profile: action.payload.profile,
        loading: false
      }
    case AccountActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case AccountActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    case AccountActions.ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        }
      }
    default:
      return state;
  }
}
