import {Resolve} from '@angular/router';
import {CalendarService} from '../services/calendar.service';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class CalendarResolve implements Resolve<any> {
  constructor(
    private calendarServ: CalendarService
  ) {}
  resolve() {
    this.calendarServ.getData();
    return null;
  }
}
