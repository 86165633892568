import { NgModule } from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import { MessageSidebarComponent } from './components/message-sidebar/message-sidebar.component';
import { MessageViewComponent } from './components/message-view/message-view.component';
import {MessagesRoutingModule} from './messages-routing.module';
import {MessagesComponent} from './messages.component';

@NgModule({
  declarations: [MessagesComponent, MessageSidebarComponent, MessageViewComponent],
  imports: [
    SharedModule, MessagesRoutingModule
  ],
  exports: [
    MessagesComponent, MessageSidebarComponent, MessageViewComponent
  ]
})
export class MessagesModule { }
