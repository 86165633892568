import {Action} from '@ngrx/store';
import {WorkoutModel} from '../../../../shared/models/training/workout.model';
import {ReviewBookmarkModel} from '../../../../shared/models/workouts/review-bookmark.model';
import {UserProfile} from '../../../../shared/models/user/user-profile';
import {ReviewRequestModel} from '../../../../shared/models/workouts/review-request.model';

export const GET_WORKOUTS_START = '[Workouts] Get workouts start';
export const GET_WORKOUTS_SUCCESS = '[Workouts] Get workouts success';
export const GET_TRAINERS_START = '[Workouts] Get trainers start';
export const GET_TRAINERS_SUCCESS = '[Workouts] Get trainers success';
export const GET_REVIEWS_START = '[Workouts] Get reviews start';
export const GET_REVIEWS_SUCCESS = '[Workouts] Get reviews success';
export const CREATE_REVIEW_START = '[Workouts] Create review start';
export const CREATE_REVIEW_SUCCESS = '[Workouts] Create review success';
export const CREATE_REVIEW_REQUESTS_START = '[Workouts] Create review requests start';
export const CREATE_REVIEW_REQUESTS_SUCCESS = '[Workouts] Create review requests success';
export const GET_REVIEW_REQUESTS_START = '[Workouts] Get review requests start';
export const GET_REVIEW_REQUESTS_SUCCESS = '[Workouts] Get review requests success';
export const UPLOAD_YT_VIDEO_START = '[Workouts] Upload YouTube video';
export const WORKOUTS_FAIL = '[Workouts] Fail';
export const CLEAR_SUCCESS = '[Workouts] Clear success';
export const CLEAR_ERROR = '[Workouts] Clear error';
export const CREATE_TRAINER_REVIEW_REQUEST_START = '[Workouts] Create workout review request start';
export const CREATE_TRAINER_REVIEW_REQUEST_SUCCESS = '[Workouts] Create workout review request success';
export const CONFIRM_REVIEW_REQUEST_START = '[Workouts] Confirm review request start';
export const CONFIRM_REVIEW_REQUEST_SUCCESS = '[Workouts] Confirm review request success';

export class GetWorkoutsStart implements Action {
  readonly type = GET_WORKOUTS_START;
  constructor() {}
}
export class GetWorkoutsSuccess implements Action {
  readonly type = GET_WORKOUTS_SUCCESS;
  constructor(public payload: {workouts: WorkoutModel[]}) {}
}
export class GetTrainersStart implements Action {
  readonly type = GET_TRAINERS_START;
  constructor() {}
}
export class UploadYouTubeVideo implements Action {
  readonly type = UPLOAD_YT_VIDEO_START;
  constructor(public payload: {videoUrl: string}) {}
}
export class GetTrainersSuccess implements Action {
  readonly type = GET_TRAINERS_SUCCESS;
  constructor(public payload: {trainers: UserProfile[]}) {}
}
export class GetReviewsStart implements Action {
  readonly type = GET_REVIEWS_START;
  constructor(public payload: {id: string}) {}
}
export class GetReviewsSuccess implements Action {
  readonly type = GET_REVIEWS_SUCCESS;
  constructor(public payload: {reviews: ReviewBookmarkModel[]}) {}
}
export class CreateReviewStart implements Action {
  readonly type = CREATE_REVIEW_START;
  constructor(public payload: {time: number, videoId: string, file: Blob}) {}
}
export class CreateReviewSuccess implements Action {
  readonly type = CREATE_REVIEW_SUCCESS;
  constructor() {}
}
export class CreateReviewRequestsStart implements Action {
  readonly type = CREATE_REVIEW_REQUESTS_START;
  constructor(public payload: {trainerId: string, id: string}) {}
}
export class CreateReviewRequestsSuccess implements Action {
  readonly type = CREATE_REVIEW_REQUESTS_SUCCESS;
  constructor(public payload: {message: string}) {}
}
export class GetReviewRequestsStart implements Action {
  readonly type = GET_REVIEW_REQUESTS_START;
  constructor() {}
}
export class GetReviewRequestsSuccess implements Action {
  readonly type = GET_REVIEW_REQUESTS_SUCCESS;
  constructor(public payload: {reviewRequests: {sent: ReviewRequestModel[], received: ReviewRequestModel[]}}) {}
}
export class WorkoutsFail implements Action {
  readonly type = WORKOUTS_FAIL;
  constructor(public payload: {errorMessage: string, errors: any}) {}
}
export class ClearErrors implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor(public payload: {message: string}) {}
}
export class CreateTrainerReviewRequestStart implements Action  {
  readonly type = CREATE_TRAINER_REVIEW_REQUEST_START;
  constructor(public payload: {studentId: string, id: string}) {}
}
export class CreateTrainerReviewRequestSuccess implements Action  {
  readonly type = CREATE_TRAINER_REVIEW_REQUEST_SUCCESS;
  constructor(public payload: {reviewRequest: ReviewRequestModel, message: string}) {}
}
export class ConfirmReviewRequestStart implements Action {
  readonly type = CONFIRM_REVIEW_REQUEST_START;
  constructor(public payload: {id: string}) {}
}
export class ConfirmReviewRequestSuccess implements Action {
  readonly type = CONFIRM_REVIEW_REQUEST_SUCCESS;
  constructor(public  payload: {reviewRequest: ReviewRequestModel, message: string}) {}
}
export type WorkoutsActions =
  GetWorkoutsStart |
  GetWorkoutsSuccess |
  GetTrainersStart |
  GetTrainersSuccess |
  GetReviewsStart |
  GetReviewsSuccess |
  CreateReviewStart |
  CreateReviewSuccess |
  CreateReviewRequestsStart |
  CreateReviewRequestsSuccess |
  GetReviewRequestsStart |
  GetReviewRequestsSuccess |
  UploadYouTubeVideo |
  WorkoutsFail |
  ClearErrors |
  ClearSuccess |
  CreateTrainerReviewRequestStart |
  CreateTrainerReviewRequestSuccess |
  ConfirmReviewRequestStart |
  ConfirmReviewRequestSuccess;
