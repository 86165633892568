import {AfterViewInit, Component, OnInit} from '@angular/core';
import {User} from '../../../../../shared/models/user/user.model';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../../store/app.reducer';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../../core/authentication/authentication.service';
import {MatTableDataSource} from '@angular/material';
import * as CalendarActions from '../../store/calendar.actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CalendarService} from '../../../../../core/services/calendar.service';

@Component({
  selector: 'app-calendar-public',
  templateUrl: './calendar-public.component.html',
  styleUrls: ['./calendar-public.component.scss']
})
export class CalendarPublicComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['title', 'description', 'date', 'started', 'finished', 'actions'];
  events;
  user: User = null;
  isLoading = true;
  isStudent = false;
  homePage = true;
  hasWebcam = false;
  hasMic = false;
  searchFormGroup: FormGroup = null;
  constructor(
    private store: Store<AppState.AppState>,
    private router: Router,
    private authSrv: AuthenticationService,
    private frmBuilder: FormBuilder,
    private calendarService: CalendarService
  ) {}

  ngOnInit() {
    this.store.dispatch(new CalendarActions.Home({home: true}));
    this.store.select('calendar').subscribe(calendarData => {
      this.isLoading = calendarData.loading;
      if (this.searchFormGroup) {
        if (this.isLoading) { this.searchFormGroup.disable(); } else { this.searchFormGroup.enable(); }
      }
      this.events = new MatTableDataSource(calendarData.events);
      this.homePage = calendarData.homePage;
    });
    this.store.select('authentication').subscribe(authState => {
      this.user = authState.user;
      this.isStudent = this.user ? this.authSrv.isStudent(authState.user.roles) : true;
    });
    this._initSearchForm();
  }
  ngAfterViewInit() {
    this.calendarService.detectWebcamAndMic((hasWebcam, hasMic) => {
      this.hasWebcam = hasWebcam;
      this.hasMic = hasMic;
    });
  }
  create() {
    this.store.dispatch(new CalendarActions.Home({home: false}));
    this.router.navigate(['calendar/public/create']);
  }
  edit(calendarEvent) {
    this.store.dispatch(new CalendarActions.Home({home: false}));
    this.router.navigate(['calendar/public/edit', calendarEvent.id]);
  }
  view(calendarEvent) {
    this.store.dispatch(new CalendarActions.Home({home: false}));
    this.router.navigate(['calendar/public/view', calendarEvent.id]);
  }
  delete(calendarEvent) {
    this.store.dispatch(new CalendarActions.DeleteStart({id: calendarEvent.id}));
  }
  open(calendarEvent) {
    this.router.navigate(['training', calendarEvent.id]);
  }
  search($event) {
    $event.preventDefault();
    const filterValue = this.searchFormGroup.get('search').value;
    if (this.searchFormGroup.valid) {
      this.events.filter = filterValue.trim().toLowerCase();
    }
  }
  private _initSearchForm() {
    this.searchFormGroup = this.frmBuilder.group({
      search: [{value: '', disabled: false}, [Validators.minLength(3)]]
    });
  }
}
