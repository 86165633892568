import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from './material.module';
import {HomeComponent} from '../pages/shared-pages/home/home.component';
import {LoadingOverlayComponent} from './components/loading-overlay/loading-overlay.component';
import {HeaderComponent} from './components/header/header.component';
import {RouterModule} from '@angular/router';
import {CustomSnackbarComponent} from './components/custom-snackbar/custom-snackbar.component';

@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    LoadingOverlayComponent,
    CustomSnackbarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HomeComponent,
    LoadingOverlayComponent,
    HeaderComponent,
    CustomSnackbarComponent,
  ]
})
export class SharedModule { }
