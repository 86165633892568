import { NgModule } from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {AccountComponent} from './account.component';
import { AccountUpdateComponent } from './components/account-update/account-update.component';
import {AccountRoutingModule} from './account-routing.module';
import { AccountViewComponent } from './components/account-view/account-view.component';


@NgModule({
  declarations: [AccountComponent, AccountUpdateComponent, AccountViewComponent],
  imports: [
    SharedModule, AccountRoutingModule
  ]
})
export class AccountModule { }
