import {Action} from '@ngrx/store';
import {UserProfile} from '../../../../shared/models/user/user-profile';

export const GET_ACCOUNT_START = '[Account] Get start';
export const GET_ACCOUNT_SUCCESSS = '[Account] Get success';
export const UPDATE_ACCOUNT_START = '[Account] Update start';
export const UPDATE_ACCOUNT_SUCCESSS = '[Account] Update success';
export const ACCOUNT_FAIL = '[Account] Fail';
export const CLEAR_ERROR = '[Account] Clear error';
export const CLEAR_SUCCESS = '[Account] Clear success';

export class GetAccountStart implements Action {
  readonly type = GET_ACCOUNT_START;
  constructor() {}
}
export class GetAccountSuccess implements Action {
  readonly type = GET_ACCOUNT_SUCCESSS;
  constructor(public payload: {profile: UserProfile}) {}
}
export class UpdateAccountStart implements Action {
  readonly type = UPDATE_ACCOUNT_START;
  constructor(public payload: {profile: UserProfile}) {}
}
export class UpdateAccountSuccess implements Action {
  readonly type = UPDATE_ACCOUNT_SUCCESSS;
  constructor(public payload: {profile: UserProfile}) {}
}
export class AccountFail implements Action {
  readonly type = ACCOUNT_FAIL;
  constructor(public payload: {errorMessage: string, errors: any}) {}
}
export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor() {}
}

export type AccountActions =
  GetAccountStart |
  GetAccountSuccess |
  UpdateAccountStart |
  UpdateAccountSuccess |
  AccountFail |
  ClearError |
  ClearSuccess;
