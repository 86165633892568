import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
// Shared modules
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationInterceptorService } from './core/authentication/authentication-interceptor.service';
import {StudentModule} from './pages/student/student/student.module';
import {TrainerModule} from './pages/trainer/trainer/trainer.module';
import {CalendarModule} from './pages/shared-pages/calendar/calendar.module';
import {AccountModule} from './pages/shared-pages/account/account.module';
import {MessagesModule} from './pages/shared-pages/messages/messages.module';
import {CustomSnackbarComponent} from './shared/components/custom-snackbar/custom-snackbar.component';
// NgRx Redux Store imports
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreModule} from '@ngrx/store';
import * as fromApp from './store/app.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AuthenticationEffects} from './authentication/store/authentication.effects';
import {CalendarEffects} from './pages/shared-pages/calendar/store/calendar.effects';
import {WorkoutsEffects} from './pages/shared-pages/workouts/store/workouts.effects';
import {AccountEffects} from './pages/shared-pages/account/store/account.effects';
import {ProfileEffects} from './pages/shared-pages/profile/store/profile.effects';
import {TrainingEffects} from './pages/shared-pages/training/store/training.effects';
import {MessagesEffects} from './pages/shared-pages/messages/store/messages.effects';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(fromApp.appReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    EffectsModule.forRoot([
      AuthenticationEffects,
      CalendarEffects,
      WorkoutsEffects,
      AccountEffects,
      ProfileEffects,
      TrainingEffects,
      MessagesEffects
    ]),
    SharedModule,
    TrainerModule,
    StudentModule,
    CalendarModule,
    AccountModule,
    MessagesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true
    }
  ],
  entryComponents: [
    CustomSnackbarComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
