import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import * as AppState from '../../../store/app.reducer';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  isLoading = false;
  constructor(
    private store: Store<AppState.AppState>
  ) { }

  ngOnInit() {
    this.store.select('profile').subscribe(data => {
      this.isLoading = data.loading;
    });
  }
}
