import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {of} from 'rxjs';
import * as WorkoutActions from '../../workouts/store/workouts.actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {BasicResponseModel} from '../../../../shared/models/basic-response.model';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error && errorRes.error.message) {
    errorMessage = errorRes.error.message;
  } else if (errorRes.error && errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message ? errorRes.error.errors.message : 'An error occurred!';
    errors = errorRes.error.errors;
  }
  return of(new WorkoutActions.WorkoutsFail({errorMessage, errors}));
};

@Injectable()
export class WorkoutsEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}
  @Effect()
  getStart = this.actions$.pipe(
    ofType(WorkoutActions.GET_WORKOUTS_START),
    switchMap((workoutsData: WorkoutActions.GetWorkoutsStart) => {
      return this.http.get<BasicResponseModel>(environment.baseApiUrl + 'users/files/get')
        .pipe(
          map(resData => {
            return new WorkoutActions.GetWorkoutsSuccess({workouts: resData.success.data});
          }),
          catchError( resError => {
            return handleError(resError);
          })
        );
    })
  );
  @Effect()
  uploadYTVideo = this.actions$.pipe(
    ofType(WorkoutActions.UPLOAD_YT_VIDEO_START),
    switchMap((action: WorkoutActions.UploadYouTubeVideo) => {
      return this.http.post<BasicResponseModel>(
        environment.baseApiUrl + 'users/youtube/upload',
        {url: action.payload.videoUrl}
        )
        .pipe(
          map(resData => {
            this.snackBar.open(resData.success.message, null, { duration: 1500 });
            return new WorkoutActions.GetWorkoutsStart();
          }),
          catchError(errorRes => {
            return handleError(errorRes);
          })
        );
    })
  );
  @Effect()
  getTrainers = this.actions$.pipe(
    ofType(WorkoutActions.GET_TRAINERS_START),
    switchMap(() => {
      return this.http.get<BasicResponseModel>(environment.baseApiUrl + 'users/trainers/list')
        .pipe(
          map(resData => {
            return new WorkoutActions.GetTrainersSuccess({trainers: resData.success.data});
          }),
          catchError(errorRes => {
            return handleError(errorRes);
          })
        );
    })
  );
  @Effect()
  getReviews = this.actions$.pipe(
    ofType(WorkoutActions.GET_REVIEWS_START),
    switchMap((action: WorkoutActions.GetReviewsStart) => {
      return this.http.post<BasicResponseModel>(environment.baseApiUrl + 'recordings/review/get', {id: action.payload.id})
        .pipe(
          map(resData => {
            return new WorkoutActions.GetReviewsSuccess({reviews: resData.success.data});
          }),
          catchError(errorRes => {
            return handleError(errorRes);
          })
        );
    })
  );
  @Effect()
  createReview = this.actions$.pipe(
    ofType(WorkoutActions.CREATE_REVIEW_START),
    switchMap((action: WorkoutActions.CreateReviewStart) => {
      const formData: FormData = new FormData();
      formData.append('time', action.payload.time.toString());
      formData.append('videoId', action.payload.videoId);
      formData.append('file', action.payload.file, 'name');
      return this.http.post<BasicResponseModel>(environment.baseApiUrl + 'recordings/review/add',
        formData, {headers: {'Content-Type': 'multipart/form-data'}}
        ).pipe(
          map(resData => {
            this.snackBar.open(resData.success.message, null, {duration: 1500});
            return new WorkoutActions.GetReviewsStart({id: resData.success.data.video.id});
          }),
          catchError(errorRes => {
            return handleError(errorRes);
          })
        );
    })
  );
  @Effect()
  createReviewRequests = this.actions$.pipe(
    ofType(WorkoutActions.CREATE_REVIEW_REQUESTS_START),
    switchMap((action: WorkoutActions.CreateReviewRequestsStart) => {
      return this.http.post<BasicResponseModel>(environment.baseApiUrl + 'recordings/review/request',
        { trainerId: action.payload.trainerId, id: action.payload.id }
        ).pipe(
          map(resData => {
            this.snackBar.open(resData.success.message, null, {duration: 1500});
            return new WorkoutActions.GetReviewRequestsStart();
          }),
          catchError(errorRes => {
            return handleError(errorRes);
          })
        );
    })
  );
  @Effect()
  createTrainerReviewRequests = this.actions$.pipe(
    ofType(WorkoutActions.CREATE_TRAINER_REVIEW_REQUEST_START),
    switchMap((action: WorkoutActions.CreateTrainerReviewRequestStart) => {
      return this.http.post<BasicResponseModel>(environment.baseApiUrl + 'recordings/trainer/review/request',
        {studentId: action.payload.studentId, id: action.payload.id }
        ).pipe(
          map(resData => {
            this.snackBar.open(resData.success.message, null, {duration: 1500});
            this.router.navigate(['review/:id', resData.success.data.id]);
            return new WorkoutActions.CreateTrainerReviewRequestSuccess({
              reviewRequest: resData.success.data,
              message: resData.success.message
            });
          }),
          catchError(errorRes => {
            return handleError(errorRes);
          })
        );
    })
  );
  @Effect()
  trainerReviewRequestSuccess = this.actions$.pipe(
    ofType(WorkoutActions.CREATE_TRAINER_REVIEW_REQUEST_SUCCESS),
    map((action: WorkoutActions.CreateTrainerReviewRequestSuccess) => {
      this.snackBar.open(action.payload.message, null, {duration: 1500});
      this.router.navigate(['review', action.payload.reviewRequest.id]);
      return new WorkoutActions.GetReviewsStart({id: action.payload.reviewRequest.id});
    })
  );
  @Effect()
  getReviewRequests = this.actions$.pipe(
    ofType(WorkoutActions.GET_REVIEW_REQUESTS_START),
    switchMap((action: WorkoutActions.GetReviewRequestsStart) => {
      return this.http.get<BasicResponseModel>(environment.baseApiUrl + 'recordings/review/request/get')
        .pipe(
          map(resData => {
            return new WorkoutActions.GetReviewRequestsSuccess({
              reviewRequests: {
                sent: resData.success.data.sent,
                received: resData.success.data.received
              }
            });
          }),
          catchError(errorRes => {
            return handleError(errorRes);
          })
        );
    })
  );
  @Effect()
  confirmReviewRequest = this.actions$.pipe(
    ofType(WorkoutActions.CONFIRM_REVIEW_REQUEST_START),
    switchMap((action: WorkoutActions.ConfirmReviewRequestStart) => {
      return this.http.post<BasicResponseModel>(
        environment.baseApiUrl + 'recordings/review/request/confirm/' + action.payload.id,
        {}
      ).pipe(
        map(resData => {
          return new WorkoutActions.ConfirmReviewRequestSuccess({
            reviewRequest: resData.success.data,
            message: resData.success.message
          });
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  confirmRequestSuccess = this.actions$.pipe(
    ofType(WorkoutActions.CONFIRM_REVIEW_REQUEST_SUCCESS),
    map((action: WorkoutActions.ConfirmReviewRequestSuccess) => {
      return new WorkoutActions.GetReviewRequestsStart();
    })
  );
}
