import { Injectable } from '@angular/core';
import {Store} from '@ngrx/store';
import * as AppState from '../../store/app.reducer';
import * as WorkoutsActions from '../../pages/shared-pages/workouts/store/workouts.actions';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkoutsService {

  constructor(
    private store: Store<AppState.AppState>,
    private http: HttpClient
  ) { }
  getData() {
    this.store.select('authentication').subscribe( data => {
      if (data.user && data.user.id) {
        this.store.dispatch(new WorkoutsActions.GetTrainersStart());
        this.store.dispatch(new WorkoutsActions.GetWorkoutsStart());
        this.store.dispatch(new WorkoutsActions.GetReviewRequestsStart());
      }
    });
  }
  deleteRequest(id: string) {
    return this.http.delete(environment.baseApiUrl + 'recordings/review/request/delete/' + id);
  }
  handleError(errorRes: any) {
    let errorMessage = 'An error occurred!';
    let errors = null;
    if (errorRes.error && errorRes.error.message) {
      errorMessage = errorRes.error.message;
    } else if (errorRes.error && errorRes.error.errors) {
      errorMessage = errorRes.error.errors.message ? errorRes.error.errors.message : 'An error occurred!';
      errors = errorRes.error.errors;
    }
    this.store.dispatch(new WorkoutsActions.WorkoutsFail({errorMessage, errors}));
  };
}
