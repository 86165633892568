import {Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {CalendarService} from '../services/calendar.service';

@Injectable({providedIn: 'root'})
export class CalendarPublicResolve implements Resolve<any> {
  constructor(
    private calendarServ: CalendarService
  ) {}
  resolve() {
    this.calendarServ.getPublicData();
    return null;
  }
}
