import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {map, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authSrv: AuthenticationService,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select('authentication').pipe(
      take(1),
      map(
        authState => {
          const isAuth = !!authState.user;
          if (isAuth) {
            if (!authState.user.roles) {
              return false;
            }
            if (next.data.roles && next.data.roles.some(r => authState.user.roles.includes(r))) {
              return true;
            }
            // No matching role found that is authorised for this route so redirect to home page
            this.router.navigate(['/']);
            return false;
          }
          return this.router.createUrlTree(['/auth']);
        }
      )
    );
  }
}
