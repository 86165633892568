import * as TrainingActions from './training.actions';

export interface State {
  loading: boolean;
  error: {
    errorMessage: string,
    errors: any
  };
}

const initialState: State = {
  loading: false,
  error: {
    errorMessage: null,
    errors: null
  }
};

export function trainingReducer(state: State = initialState, action: TrainingActions.TrainingActions) {
  switch (action.type) {
    case TrainingActions.CLOSE_EVENT_START:
      return {
        ...state,
        loading: true,
        error: null
      }
    case TrainingActions.CLOSE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}
