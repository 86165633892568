import {TrainerComponent} from './trainer.component';
import {AuthenticationGuard} from '../../../core/authentication/authentication.guard';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

const routes = [
  {
    path: '',
    component: TrainerComponent,
    canActivate: [AuthenticationGuard]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class TrainerRoutingModule {}
