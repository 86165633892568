import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import * as fromApp from '../../../store/app.reducer';
import * as AuthenticationActions from '../../../authentication/store/authentication.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  isTrainer = false;
  isStudent = false;
  isAdmin = false;
  email: string = null;
  userId: string = null;
  userSub: Subscription;
  newMessage = false;
  constructor(
    private authSrv: AuthenticationService,
    private store: Store<fromApp.AppState>
  ) { }

  ngOnInit() {
    this.userSub = this.store.select('authentication')
      .pipe(
        map(authState => {
          return authState.user;
        })
      )
      .subscribe(user => {
        this.isLoggedIn = !!user;
        if (this.isLoggedIn) {
          this.email = user.email;
          this.userId = user.id;
          this.isTrainer = this.authSrv.isTrainer(user.roles);
          this.isStudent = this.authSrv.isStudent(user.roles);
          this.isAdmin = this.authSrv.isAdmin(user.roles);
        } else { this.email = null; this.userId = null; }
      });
    this.store.select('messages').subscribe(data => {
      this.newMessage = data.newMessage.received;
    });
  }
  onLogout() {
    this.store.dispatch(
      new AuthenticationActions.Logout()
    );
  }
  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
