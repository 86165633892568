import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../../store/app.reducer';
import * as AccountActions from '../../store/account.actions';
import {UserProfile} from '../../../../../shared/models/user/user-profile';

@Component({
  selector: 'app-account-update',
  templateUrl: './account-update.component.html',
  styleUrls: ['./account-update.component.scss']
})
export class AccountUpdateComponent implements OnInit {
  isLoading = false;
  error = null;
  updateAccountForm: FormGroup = null;
  profile: UserProfile = null;

  constructor(
    private router: Router,
    private store: Store<AppState.AppState>,
    private frmBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.store.select('account').subscribe(data => {
      this.profile = data.profile;
      this.isLoading = data.loading;
      this.error = data.error;
    });
    this._initForm();
  }
  back() {
    this.router.navigate(['account']);
  }
  submit() {
    const updatedProfile =  this.updateAccountForm.value;
    if (!this.updateAccountForm.get('password').value) {
      delete updatedProfile.password;
      delete updatedProfile.passwordConfirm;
    }
    if (this.updateAccountForm.get('email').value === this.profile.email) {
      delete updatedProfile.email;
    }
    this.store.dispatch(new AccountActions.UpdateAccountStart({
      profile: updatedProfile
    }));
  }
  get email() { return this.updateAccountForm.get('email'); }
  get firstName() { return this.updateAccountForm.get('firstName'); }
  get lastName() { return this.updateAccountForm.get('lastName'); }
  get password() { return this.updateAccountForm.get('password'); }
  get passwordConfirm() { return this.updateAccountForm.get('passwordConfirm'); }
  private _initForm() {
    this.updateAccountForm = this.frmBuilder.group({
      email: [this.profile.email, Validators.required],
      firstName: [this.profile.firstName,  [Validators.required, Validators.minLength(3)]],
      lastName: [this.profile.lastName,  [Validators.required, Validators.minLength(3)]],
      password: ['', Validators.minLength(7)],
      passwordConfirm: ['',  Validators.minLength(7)],
    }, {validators: this._passwordMatch('password', 'passwordConfirm')});
  }
  private _passwordMatch(password: string, passwordConfirm: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[password];
      const passwordConfirmationInput = group.controls[passwordConfirm];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({notEquivalent: true});
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
}
