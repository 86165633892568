import {Action} from '@ngrx/store';
import {EventModel} from '../../../../shared/models/calendar/eventModel';
import {UserProfile} from '../../../../shared/models/user/user-profile';
import {WorkoutModel} from '../../../../shared/models/training/workout.model';

export const CREATE_EVENT_START = '[Calendar] Create calendarEvent start';
export const CREATE_EVENT_SUCCESS = '[Calendar] Create calendarEvent success';
export const GET_EVENT_START = '[Calendar] Get start';
export const GET_PUBLIC_EVENT_START = '[Calendar] Get public events start';
export const FILTER_PUBLIC_EVENT_START = '[Calendar] Filter public events start';
export const GET_EVENT_SUCCESS = '[Calendar] Get success';
export const GET_USERS_START = '[Calendar] Get users start';
export const GET_USERS_SUCCESS = '[Calendar] Get users success';
export const UPDATE_EVENT_START = '[Calendar] Update event start';
export const UPDATE_EVENT_SUCCESS = '[Calendar] Update event success';
export const DELETE_START = '[Calendar] Delete start';
export const DELETE_SUCCESS = '[Calendar] Delete success';
export const CALENDAR_FAIL = '[Calendar] Calendar Fail';
export const CLEAR_ERROR = '[Calendar] Clear Errors';
export const CLEAR_SUCCESS = '[Calendar] Clear Success';
export const HOME = '[Calendar] Home page';
export const SUBSCRIBE_TO_EVENTS = '[Calendar] Subscribe to event';
export const START_EVENT_SUCCESS = '[Calendar] Start event success';
export const EVENT_STARTED = '[Calendar] Event started';
export const GET_TRAINING_WORKOUTS_START = '[Calendar] Get workouts per training start';
export const GET_TRAINING_WORKOUTS_SUCCESS = '[Calendar] Get workouts per training success';
export const CLEAR_STORE = '[Calendar] Clear store';

export class CreateEventStart implements Action {
  readonly type = CREATE_EVENT_START;
  constructor(public payload: {event: EventModel}) {}
}
export class CreateEventSuccess implements Action {
  readonly type = CREATE_EVENT_SUCCESS;
  constructor(public payload: {event: EventModel, message: string}) {}
}
export class GetEventStart implements Action {
  readonly type = GET_EVENT_START;
  constructor(public payload: {id: string}) {}
}
export class GetEventSuccess implements Action {
  readonly type = GET_EVENT_SUCCESS;
  constructor(public payload: {events: EventModel[]}) {}
}
export class GetPublicEventStart implements Action {
  readonly type = GET_PUBLIC_EVENT_START;
  constructor() {}
}
export class FilterPublicEventStart implements Action {
  readonly type = FILTER_PUBLIC_EVENT_START;
  constructor(public payload: {filter: string}) {}
}
export class GetUserStart implements Action {
  readonly type = GET_USERS_START;
  constructor(public payload: {role: string}) {}
}
export class GetUserSucces implements Action {
  readonly type = GET_USERS_SUCCESS;
  constructor(public payload: {users: UserProfile[]}) {}
}
export class UpdateEventStart implements Action {
  readonly type = UPDATE_EVENT_START;
  constructor(public payload: {event: EventModel}) {}
}
export class UpdateEventSuccess implements Action {
  readonly type = UPDATE_EVENT_SUCCESS;
  constructor(public payload: {event: EventModel, message: string}) {}
}
export class DeleteStart implements Action {
  readonly type = DELETE_START;
  constructor(public payload: {id: string}) {}
}
export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: {id: string, message: string}) {}
}
export class CalendarFail implements Action {
  readonly type = CALENDAR_FAIL;
  constructor(public payload: {errorMessage: string, errors: any}) {}
}
export class ClearErrors implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor(public payload: {message: string}) {}
}
export class Home implements Action {
  readonly type = HOME;
  constructor(public payload: {home: boolean}) {}
}
export class SubscribeToEvents implements Action {
  readonly type = SUBSCRIBE_TO_EVENTS;
  constructor(public payload: {eventts: string[]}) {}
}
export class StartEventSuccess implements Action {
  readonly type = START_EVENT_SUCCESS;
  constructor(public payload: {message: string, id: string}) {}
}
export class EventStarted implements Action {
  readonly type = EVENT_STARTED;
  constructor(public payload: {id: string, message: string}) {}
}
export class GetTrainingWorkoutsStart implements Action {
  readonly type = GET_TRAINING_WORKOUTS_START;
  constructor(public payload: {id: string}) {}
}
export class GetTrainingWorkoutsSuccess implements Action {
  readonly type = GET_TRAINING_WORKOUTS_SUCCESS;
  constructor(public payload: {eventReview: EventModel}) {}
}
export class ClearStore implements Action {
  readonly type = CLEAR_STORE;
  constructor() {}
}

export type CalendarActions =
  CreateEventStart |
  CreateEventSuccess |
  GetEventStart |
  GetPublicEventStart |
  FilterPublicEventStart |
  GetEventSuccess |
  GetUserStart |
  GetUserSucces |
  UpdateEventStart |
  UpdateEventSuccess |
  DeleteStart |
  DeleteSuccess |
  CalendarFail |
  ClearErrors |
  ClearSuccess |
  SubscribeToEvents |
  StartEventSuccess |
  EventStarted |
  Home |
  GetTrainingWorkoutsStart |
  GetTrainingWorkoutsSuccess |
  ClearStore;
