import { Component, OnInit } from '@angular/core';
import {connect} from 'twilio-video';

@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.scss']
})
export class TrainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
