export const environment = {
  production: false,
  baseApiUrl: 'https://gym-api.softerrific.com/api/',
  twilio: {
    sid: 'AC365114c40a1338edb26664066c2c6d45',
    token: 'e8db5467e9c238c6e02df062186f2c64',
  },
  broadcasting: {
    broadcaster: 'pusher',
    key: 'a8d32bfcb82f77b66146',
    cluster: 'eu',
    forceTLS: true
  }
};
