import {Action} from '@ngrx/store';
import {ProfileModel} from '../../../../shared/models/user/profile.model';

export const GET_PROFILE_START = '[Profile] Get start';
export const GET_PROFILE_SUCCESS = '[Profile] Get success';
export const UPDATE_PROFILE_START = '[Profile] Update start';
export const UPDATE_PROFILE_SUCCESS = '[Profile] Update success';
export const PROFILE_FAIL = '[Profile] Failed';
export const CLEAR_ERROR = '[Profile] Clear error';
export const CLEAR_SUCCESS = '[Profile] Clear success';
export const GET_ALL_PROFILES_START = '[Profile] Get all profiles start';
export const GET_ALL_PROFILES_SUCCESS = '[Profile] Get all profiles success';

export class GetProfileStart implements Action {
  readonly type = GET_PROFILE_START;
  constructor(public payload: {id: string}) {}
}
export class GetProfileSuccess implements Action {
  readonly type = GET_PROFILE_SUCCESS;
  constructor(public payload: {profile: ProfileModel}) {}
}
export class UpdateProfileStart implements Action {
  readonly type = UPDATE_PROFILE_START;
  constructor(public payload: {bio: string, avatar: File}) {}
}
export class UpdateProfileSuccess implements Action {
  readonly type = UPDATE_PROFILE_SUCCESS;
  constructor(public payload: {profile: ProfileModel, message: string}) {}
}
export class ProfileFail implements Action {
  readonly type = PROFILE_FAIL;
  constructor(public payload: {errorMessage: string, errors: any}) {}
}
export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor() {}
}
export class GetAllProfilesStart implements Action {
  readonly type = GET_ALL_PROFILES_START;
  constructor(public payload: {filter: string}) {}
}
export class GetAllProfilesSuccess implements Action {
  readonly type = GET_ALL_PROFILES_SUCCESS;
  constructor(public payload: {profiles: ProfileModel[]}) {}
}

export type ProfileActions =
  GetProfileStart |
  GetProfileSuccess |
  UpdateProfileStart |
  UpdateProfileSuccess |
  ProfileFail |
  ClearError |
  ClearSuccess |
  GetAllProfilesStart |
  GetAllProfilesSuccess;
