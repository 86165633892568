import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as ProfileActions from './profile.actions';
import {of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {BasicResponseModel} from '../../../../shared/models/basic-response.model';
import {environment} from '../../../../../environments/environment';
import {MatSnackBar} from '@angular/material';
import {Location} from '@angular/common';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error && errorRes.error.message) {
    errorMessage = errorRes.error.message;
  } else if (errorRes.error && errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message ? errorRes.error.errors.message : 'An error occurred!';
    errors = errorRes.error.errors;
  }
  return of(new ProfileActions.ProfileFail({errorMessage, errors}));
};
@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private location: Location
  ) {}
  @Effect()
  getProfile = this.actions$.pipe(
    ofType(ProfileActions.GET_PROFILE_START),
    switchMap((action: ProfileActions.GetProfileStart) => {
      return this.http.get<BasicResponseModel>(
        environment.baseApiUrl + 'user/' + action.payload.id + '/profile/get'
      ).pipe(
        map(resData => {
          return new ProfileActions.GetProfileSuccess({profile: resData.success.data});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  updateProfile = this.actions$.pipe(
    ofType(ProfileActions.UPDATE_PROFILE_START),
    switchMap((action: ProfileActions.UpdateProfileStart) => {
      const uploadData = new FormData();
      uploadData.append('bio', action.payload.bio);
      if (action.payload.avatar) {
        uploadData.append('avatar', action.payload.avatar, 'avatar');
      }
      return this.http.post<BasicResponseModel>(
        environment.baseApiUrl + 'user/profile/update', uploadData
      ).pipe(
        map(resData => {
          return new ProfileActions.UpdateProfileSuccess({
            profile: resData.success.data,
            message: resData.success.message
          });
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect({dispatch: false})
  updateSuccess = this.actions$.pipe(
    ofType(ProfileActions.UPDATE_PROFILE_SUCCESS),
    tap((action: ProfileActions.UpdateProfileSuccess) => {
      this.snackbar.open(action.payload.message, 'Close', {duration: 2500});
      this.location.back();
    })
  );
  @Effect()
  getAllProfiles = this.actions$.pipe(
    ofType(ProfileActions.GET_ALL_PROFILES_START),
    switchMap((action: ProfileActions.GetAllProfilesStart) => {
      return this.http.get<BasicResponseModel>(
        environment.baseApiUrl + 'users/get/list'
      ).pipe(
        map(resData => {
          return new ProfileActions.GetAllProfilesSuccess({profiles: resData.success.data});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
}
