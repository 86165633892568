import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MessagesComponent} from './messages.component';
import {AuthenticationGuard} from '../../../core/authentication/authentication.guard';
import {MessageViewComponent} from './components/message-view/message-view.component';
import {MessagesResolve} from '../../../core/resolvers/messages.resolve';

const routes = [
  {
    path: '',
    component: MessagesComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {path: 'view/:id', component: MessageViewComponent}
    ],
    resolve: {data: MessagesResolve}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class MessagesRoutingModule {}
