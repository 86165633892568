import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {catchError, exhaustMap, map, take} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as AuthenticationActions from '../../authentication/store/authentication.actions';
import {MatSnackBar} from '@angular/material';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptorService implements HttpInterceptor {
  constructor(
    private authSrv: AuthenticationService,
    private store: Store<fromApp.AppState>,
    private snackBar: MatSnackBar
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select('authentication').pipe(
      take(1),
      map(authState => {
        return authState.user;
      }),
      exhaustMap(user => {
        if (!user) {
          return next.handle(req);
        }
        const modifedRequest = req.clone({
          headers: new HttpHeaders()
            .set('Accept', 'application/json')
            .set('Authorization', 'Bearer ' + user.token)
        });
        return next.handle(modifedRequest).pipe(
          catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.snackBar.open(
                  'Token expired or is invalid. Please login.',
                  'Close',
                  { duration: 3500 });
                localStorage.removeItem('userData');
                this.store.dispatch(new AuthenticationActions.Logout());
              }
            }
            return throwError(err);
          })
        );
      })
    );
  }
}
