import { Injectable } from '@angular/core';
import {Store} from '@ngrx/store';
import * as AppState from '../../store/app.reducer';
import * as CalendarActions from '../../pages/shared-pages/calendar/store/calendar.actions';
import {Roles} from '../../shared/enums/roles.enum';
import Echo from 'laravel-echo';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  echo: any;
  constructor(
    private store: Store<AppState.AppState>,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }
  getData() {
    this.store.select('authentication').subscribe(resData => {
      if (resData.user) {
        this.store.dispatch(new CalendarActions.GetEventStart({id: resData.user.id}));
        this.store.dispatch(new CalendarActions.GetUserStart({role: Roles.Student}));
      }
    });
  }
  getPublicData() {
    this.store.dispatch(new CalendarActions.GetPublicEventStart());
  }
  subscribeToEventsBroadCast() {
    this.echo = new Echo({
      broadcaster: environment.broadcasting.broadcaster,
      key: environment.broadcasting.key,
      cluster: environment.broadcasting.cluster,
      forceTLS: environment.broadcasting.forceTLS
    });
    const channel = this.echo.channel('events')
    channel.listen('.App\\Events\\EventStarted', (e) => {
      this.store.dispatch(new CalendarActions.EventStarted({id: e.id, message: e.message}));
    });
  }
  detectWebcamAndMic(callback) {
    const md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) { return callback(false); }
    md.enumerateDevices().then(devices => {
      callback(devices.some(device => 'videoinput' === device.kind), devices.some(device => 'audioinput' === device.kind));
    });
  }
  navigateBack() {
    const url = this.router.url;
    if (url.includes('calendar/private')) {
      this.router.navigate(['calendar/private']);
    } else if (url.includes('calendar/public')) {
      this.router.navigate(['calendar/public']);
    } else {
      this.router.navigate(['/']);
    }
  }
}
