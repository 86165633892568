import {Action} from '@ngrx/store';
import {Roles} from '../../shared/enums/roles.enum';
import {UserProfile} from '../../shared/models/user/user-profile';

export const REGISTER_START = '[Auth] Register start';
export const LOGIN_START = '[Auth] Login start';
export const AUTHENTICATE_SUCCESS = '[Auth] AuthenticateSuccess';
export const AUTHENTICATE_FAIL = '[Auth] AuthenticateSuccess fail';
export const AUTO_LOGIN = '[Auth] Auto login';
export const LOGOUT = '[Auth] Logout';
export const PROFILE_START = '[Auth] Profile get start';
export const PROFILE_SUCCESS = '[Auth] Profile get success';
export const CHECK_TOKEN_FAIL = '[Auth] Check token fail';
export const RESET_PASSWORD_EMAIL_START = '[Auth] Send reset password email start';
export const RESET_PASSWORD_EMAIL_SUCCESS = '[Auth] Send reset password email success';
export const CHECK_TOKEN_START = '[Auth] Check token start';
export const CHECK_TOKEN_SUCCESS = '[Auth] Check token success';
export const RESET_PASSWORD_START = '[Auth] Reset password start';
export const RESET_PASSWORD_SUCCESS = '[Auth] Reset password success';

export class RegisterStart implements Action {
  readonly type = REGISTER_START;
  constructor(public payload: {
    email: string,
    password: string,
    passwordConfirm: string,
    firstName: string,
    lastName: string,
    roles: Roles[]
  }) {}
}
export class LoginStart implements Action {
  readonly type = LOGIN_START;
  constructor(public payload: {email: string, password: string}) {}
}

export class AuthenticateSuccess implements Action {
  readonly type = AUTHENTICATE_SUCCESS;

  constructor(public payload: {
    email: string;
    token: string;
    expirationDate: Date;
    roles: Roles[];
    id: string;
  }) {}
}
export class AuthenticateFail implements Action {
  readonly type = AUTHENTICATE_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}
export class AutoLogin implements Action {
  readonly  type = AUTO_LOGIN;
}
export class Logout implements Action {
  readonly type = LOGOUT;
}
export class ProfileStart implements Action {
  readonly type = PROFILE_START;
  constructor() {}
}
export class ProfileSuccess implements Action {
  readonly type = PROFILE_SUCCESS;
  constructor(public payload: {profile: UserProfile}) {}
}
export class ResetPasswordEmailStart implements Action {
  readonly type = RESET_PASSWORD_EMAIL_START;
  constructor(public payload: {email: string}) {}
}
export class ResetPasswordEmailSuccess implements Action {
  readonly type = RESET_PASSWORD_EMAIL_SUCCESS;
  constructor(public payload: {message: string}) {}
}

export class CheckTokenStart implements Action {
  readonly  type = CHECK_TOKEN_START;
  constructor(public payload: {email: string, token: string}) {}
}
export class CheckTokenSuccess implements Action {
  readonly  type = CHECK_TOKEN_SUCCESS;
  constructor(public payload: {message: string, isTokenValid: boolean}) {}
}
export class CheckTokenFail implements Action {
  readonly  type = CHECK_TOKEN_FAIL;
  constructor(public payload: {message: string, isTokenValid: boolean}) {}
}
export class ResetPasswordStart implements Action {
  readonly type = RESET_PASSWORD_START;
  constructor(public payload: {email: string, password: string, token: string}) {}
}
export class ResetPasswordSuccess implements Action {
  readonly type = RESET_PASSWORD_SUCCESS;
  constructor(public payload: {message: string}) {}
}

export type AuthenticationActions =
  RegisterStart |
  LoginStart |
  AuthenticateSuccess |
  AuthenticateFail |
  AutoLogin |
  Logout |
  ProfileStart |
  ProfileSuccess |
  ResetPasswordEmailStart |
  ResetPasswordEmailSuccess |
  CheckTokenStart |
  CheckTokenSuccess |
  CheckTokenFail |
  ResetPasswordStart |
  ResetPasswordSuccess;
