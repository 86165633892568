import {NgModule} from '@angular/core';
import {RouterModule, RunGuardsAndResolvers} from '@angular/router';
import {CalendarComponent} from './calendar.component';
import {AuthenticationGuard} from '../../../core/authentication/authentication.guard';
import {CalendarCreateComponent} from './components/calendar-create/calendar-create.component';
import {CalendarEditComponent} from './components/calendar-edit/calendar-edit.component';
import {CalendarViewComponent} from './components/calendar-view/calendar-view.component';
import {CalendarPrivateComponent} from './components/calendar-private/calendar-private.component';
import {CalendarPublicComponent} from './components/calendar-public/calendar-public.component';
import {CalendarPublicResolve} from '../../../core/resolvers/calendar-public.resolve';
import {CalendarResolve} from '../../../core/resolvers/calendar.resolve';
import {CalendarWorkoutsComponent} from './components/calendar-workouts/calendar-workouts.component';
import {Roles} from '../../../shared/enums/roles.enum';

const routes = [
  {
    path: '',
    component: CalendarComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {path: '', redirectTo: 'private', pathMatch: 'full'},
      {path: 'workouts', component: CalendarWorkoutsComponent, data: {roles: [Roles.Trainer, Roles.Admin]}},
      {
        path: 'private',
        resolve: {data: CalendarResolve},
        runGuardsAndResolvers: 'always' as RunGuardsAndResolvers,
        component: CalendarPrivateComponent,
        children: [
          {path: 'create', component: CalendarCreateComponent},
          {path: 'edit/:id', component: CalendarEditComponent},
          {path: 'view/:id', component: CalendarViewComponent}
        ]
      },
      {
        path: 'public',
        component: CalendarPublicComponent,
        resolve: {data: CalendarPublicResolve},
        runGuardsAndResolvers: 'always' as RunGuardsAndResolvers,
        children: [
          {path: 'create', component: CalendarCreateComponent},
          {path: 'edit/:id', component: CalendarEditComponent},
          {path: 'view/:id', component: CalendarViewComponent}
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class CalendarRoutingModule {}
