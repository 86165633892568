import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../../store/app.reducer';
import * as MessagesActions from '../../store/messages.actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';

@Component({
  selector: 'app-message-sidebar',
  templateUrl: './message-sidebar.component.html',
  styleUrls: ['./message-sidebar.component.scss']
})
export class MessageSidebarComponent implements OnInit {
  profiles;
  latest;
  userList;
  searchFormGroup: FormGroup = null;
  isLoading = false;
  displayedColumns: string[] = ['name', 'email', 'newMessage'];
  newMessage = false;
  fromUserId = null;
  constructor(
    private store: Store<AppState.AppState>,
    private frmBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this._initSearchForm();
    this.store.select('profile').subscribe(data => {
      this.profiles = data.userProfiles;
    });
    this.store.select('messages').subscribe(data => {
      this.latest = data.latest;
      this.isLoading = data.loading;
      if (!this.searchFormGroup.get('search').value) {
        this.userList = new MatTableDataSource(this.latest);
      }
      this.newMessage = data.newMessage.received;
      if (this.newMessage) { this.fromUserId = data.newMessage.fromUserId; }
    });
  }
  search($event) {
    $event.preventDefault();
    const filterValue = this.searchFormGroup.get('search').value;
    if (filterValue) {
      this.userList.data = this.profiles;
      this.userList.filter = filterValue.trim().toLowerCase();
    } else {
      this.userList.data = this.latest;
    }
  }
  private _initSearchForm() {
    this.searchFormGroup = this.frmBuilder.group({
      search: [{value: '', disabled: false}, [Validators.minLength(3)]]
    });
  }
  openMessages(element) {
    this.store.dispatch(new MessagesActions.GetMessagesStart({id: element.id}));
    this.router.navigate(['messages/view', element.id]);
  }
}
