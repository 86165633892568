import { NgModule } from '@angular/core';
import { StudentComponent } from './student.component';
import {SharedModule} from '../../../shared/shared.module';
import {StudentRoutingModule} from './student-routing.module';

@NgModule({
  declarations: [StudentComponent],
  imports: [
    SharedModule, StudentRoutingModule
  ]
})
export class StudentModule { }
