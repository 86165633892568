import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import {environment} from '../../../environments/environment';
import {Store} from '@ngrx/store';
import * as AppState from '../../store/app.reducer';
import * as MessagesActions from '../../pages/shared-pages/messages/store/messages.actions';
import * as ProfileActions from '../../pages/shared-pages/profile/store/profile.actions';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  echo: any;
  constructor(
    private store: Store<AppState.AppState>
  ) { }
  getData() {
    this.store.dispatch(new ProfileActions.GetAllProfilesStart({filter: ''}));
    this.store.dispatch(new MessagesActions.GetLatestStart());
  }
  subscribeToMessagesBroadCast() {
    this.echo = new Echo({
      broadcaster: environment.broadcasting.broadcaster,
      key: environment.broadcasting.key,
      cluster: environment.broadcasting.cluster,
      forceTLS: environment.broadcasting.forceTLS
    });
    const channel = this.echo.channel('messages');
    channel.listen('.App\\Events\\MessageSent', (e) => {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData && userData._id === e.recipientId) {
        this.store.dispatch(new MessagesActions.ReceivedMessage({
            recipientId: e.recipientId,
            senderId: e.senderId,
            message: e.message
          })
        );
        this.store.dispatch(new MessagesActions.GetLatestStart());
      }
    });
  }
}
