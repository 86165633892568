import { NgModule } from '@angular/core';
import { CalendarComponent } from './calendar.component';
import { CalendarCreateComponent } from './components/calendar-create/calendar-create.component';
import {SharedModule} from '../../../shared/shared.module';
import {CalendarRoutingModule} from './calendar-routing.module';
import { CalendarEditComponent } from './components/calendar-edit/calendar-edit.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { CalendarPrivateComponent } from './components/calendar-private/calendar-private.component';
import { CalendarPublicComponent } from './components/calendar-public/calendar-public.component';
import { CalendarWorkoutsComponent } from './components/calendar-workouts/calendar-workouts.component';

@NgModule({
  declarations: [
    CalendarComponent,
    CalendarCreateComponent,
    CalendarEditComponent,
    CalendarViewComponent,
    CalendarPrivateComponent,
    CalendarPublicComponent,
    CalendarWorkoutsComponent
  ],
  imports: [
    CalendarRoutingModule, SharedModule
  ]
})
export class CalendarModule { }


