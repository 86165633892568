import {Action} from '@ngrx/store';
import {MessageModel} from '../../../../shared/models/messages/message.model';
import {UserProfile} from '../../../../shared/models/user/user-profile';

export const GET_MESSAGES_START = '[Messages] Get messages start';
export const GET_MESSAGES_SUCCESS = '[Messages] Get messages success';
export const GET_LATEST_START = '[Messages] Get latest start';
export const GET_LATEST_SUCCESS = '[Messages] Get latest success';
export const SEND_MESSAGE_START = '[Messages] Send message start';
export const SEND_MESSAGE_SUCCESS = '[Messages] Send message success';
export const MESSAGES_FAIL = '[Messages] Fail';
export const CLEAR_ERROR = '[Messages] Clear error';
export const RECEIVED_MESSAGE = '[Messages] Received messages';
export const CLEAR_CURRENT_USER = '[Messages] Clear the current user';
export const CLEAR_STORE = '[Messages] Clear store';

export class GetMessagesStart implements Action {
  readonly type = GET_MESSAGES_START;
  constructor(public payload: {id: string}) {}
}
export class GetMessagesSuccess implements Action {
  readonly type = GET_MESSAGES_SUCCESS;
  constructor(public payload: {messages: MessageModel[]}) {}
}
export class GetLatestStart implements Action {
  readonly type = GET_LATEST_START;
  constructor() {}
}
export class GetLatestSuccess implements Action {
  readonly type = GET_LATEST_SUCCESS;
  constructor(public payload: {latest: UserProfile[]}) {}
}
export class SendMessageStart implements Action {
  readonly type = SEND_MESSAGE_START;
  constructor(public payload: {message: string, recipientId: string}) {}
}
export class SendMessageSuccess implements Action {
  readonly type = SEND_MESSAGE_SUCCESS;
  constructor(public payload: {message: MessageModel}) {}
}
export class MessagesFail implements Action {
  readonly type = MESSAGES_FAIL;
  constructor(public payload: {errorMessage: string, errors: any}) {}
}
export class ClearErrors implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ReceivedMessage implements Action {
  readonly type = RECEIVED_MESSAGE;
  constructor(public payload: {recipientId: string, senderId: string, message: MessageModel}) {}
}
export class ClearCurrentUser implements Action {
  readonly type = CLEAR_CURRENT_USER;
  constructor() {}
}
export class ClearStore implements Action {
  readonly type = CLEAR_STORE;
  constructor() {}
}

export type MessagesActions =
  GetMessagesStart |
  GetMessagesSuccess |
  GetLatestStart |
  GetLatestSuccess |
  SendMessageStart |
  SendMessageSuccess |
  MessagesFail |
  ClearErrors |
  ReceivedMessage |
  ClearCurrentUser |
  ClearStore;
