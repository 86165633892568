import {Component, HostListener, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../../store/app.reducer';
import * as MessagesActions from '../../store/messages.actions';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ProfileModel} from '../../../../../shared/models/user/profile.model';

@Component({
  selector: 'app-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.scss']
})
export class MessageViewComponent implements OnInit {
  isLoading = true;
  profile;
  profileList: ProfileModel[] = [];
  id: string = null;
  userData = null;
  error = null;
  messageGroup: FormGroup = null;
  messages = [];
  currentUser: string = null;
  constructor(
    private store: Store<AppState.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private frmBuilder: FormBuilder
  ) { }
  get invalidMessage() { return this.messageGroup.get('message').value === ''; }
  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.id = this.route.snapshot.paramMap.get('id');
    this.router.events.subscribe(events => {
      if (events instanceof NavigationEnd) {
        this.id = this.route.snapshot.paramMap.get('id');
        if (this.profileList.length) {
          this.profile = this.profileList.find(p => p.id === this.id);
        }
        if (!events.url.includes('messages/view') && this.currentUser) {
          this.store.dispatch(new MessagesActions.ClearCurrentUser());
        }
      }
    });
    this.store.select('profile').subscribe(data => {
      this.profileList = data.userProfiles;
      if (!this.profile) {
        this.profile = this.profileList.find(p => p.id === this.id);
      }
      this.error = data.error;
    });
    this.store.select('messages').subscribe(data => {
      this.messages = data.messages;
      this.isLoading = data.loading;
      this.currentUser = data.currentUser;
    });
    this._initForm();
  }
  private _initForm() {
    this.messageGroup = this.frmBuilder.group({
      message: ['']
    });
  }
  submit($event) {
    $event.preventDefault();
    let message = this.messageGroup.get('message').value;
    if (!this.invalidMessage) {
      message = message.trim().toLowerCase();
      this.store.dispatch(new MessagesActions.SendMessageStart({
        message,
        recipientId: this.id
      }));
    }
    this.messageGroup.get('message').setValue('');
  }
}
