import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import * as TrainingActions from './training.actions';
import {tap} from 'rxjs/operators';

@Injectable()
export class TrainingEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router
  ) {}

  @Effect({dispatch: false})
  evenCloseSuccess = this.actions$.pipe(
    ofType(TrainingActions.CLOSE_EVENT_SUCCESS),
    tap(() => {
      this.router.navigate(['calendar']);
    })
  );
}
