import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../../store/app.reducer';
import {UserProfile} from '../../../../../shared/models/user/user-profile';

@Component({
  selector: 'app-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.scss']
})
export class AccountViewComponent implements OnInit {
  isLoading = false;
  error = null;
  profile: UserProfile = null;
  constructor(
    private router: Router,
    private store: Store<AppState.AppState>
  ) { }

  ngOnInit() {
    this.store.select('account').subscribe(data => {
      this.profile = data.profile;
      this.isLoading = data.loading;
      this.error = data.error;
    });
  }
  update() {
    this.router.navigate(['account/update']);
  }
}
