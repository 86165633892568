import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as AppState from '../../../../../store/app.reducer';
import * as CalendarActions from '../../store/calendar.actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {EventModel} from '../../../../../shared/models/calendar/eventModel';
import * as Moment from 'moment';
import {UserProfile} from '../../../../../shared/models/user/user-profile';
import {CalendarService} from '../../../../../core/services/calendar.service';

@Component({
  selector: 'app-calendar-create',
  templateUrl: './calendar-create.component.html',
  styleUrls: ['./calendar-create.component.scss']
})
export class CalendarCreateComponent implements OnInit {
  isLoading = false;
  error = null;

  createFormGroup: FormGroup;
  hoursArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutesArray = ['00', '15', '30', '45'];
  usersArray: UserProfile[] = [];
  constructor(
    private store: Store<AppState.AppState>,
    private formBuilder: FormBuilder,
    private router: Router,
    private calendarServ: CalendarService
  ) { }

  ngOnInit() {
    this.store.select('calendar').subscribe(calendarState => {
      this.isLoading = calendarState.loading;
      this.error = calendarState.error;
      this.usersArray = calendarState.users;
    });
    this._initForm();
  }
  private _initForm() {
    this.createFormGroup = this.formBuilder.group({
      date: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      students: ['', Validators.required],
      public: [false],
      hours: ['', Validators.required],
      minutes: ['', Validators.required]
    });
    if (this.router.url.includes('calendar/public')) {
      this.createFormGroup.get('public').setValue(true);
      this.createFormGroup.get('students').disable();
    }
  }
  createCalendar(): EventModel {
    const hours = +this.createFormGroup.get('hours').value;
    const minutes = +this.createFormGroup.get('minutes').value;
    const date = this.createFormGroup.get('date').value;
    date.setHours(hours);
    date.setMinutes(minutes);
    const startDate = date.toISOString();
    date.setHours(hours + 1)
    const endDate = date.toISOString();
    return {
      start: startDate,
      end: endDate,
      description: this.createFormGroup.get('description').value,
      title: this.createFormGroup.get('title').value,
      students: this.createFormGroup.get('public').value ? [] : this.createFormGroup.get('students').value,
      public: !!this.createFormGroup.get('public').value
    };
  }
  back() {
    this.store.dispatch(new CalendarActions.Home({home: true}));
    this.calendarServ.navigateBack();
  }
  submit($event) {
    $event.preventDefault();
    const newCalendar = this.createCalendar();
    this.store.dispatch(new CalendarActions.CreateEventStart({
        event: newCalendar
    }));
  }
  toggleStudents($event) {
    if ($event.checked) {
      this.createFormGroup.get('students').disable();
      this.createFormGroup.get('students').clearValidators();
    } else {
      this.createFormGroup.get('students').enable();
      this.createFormGroup.get('students').setValidators([Validators.required]);
    }
  }
}
