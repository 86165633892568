import * as WorkoutsActions from './workouts.actions';
import {WorkoutModel} from '../../../../shared/models/training/workout.model';
import {UserProfile} from '../../../../shared/models/user/user-profile';
import {ReviewRequestModel} from '../../../../shared/models/workouts/review-request.model';

export interface State {
  workouts: WorkoutModel[];
  trainers: UserProfile[];
  reviews: any;
  reviewRequests: {
    sent: ReviewRequestModel[],
    received: ReviewRequestModel[],
  };
  error: {
    errorMessage: string,
    errors: any
  };
  success: string;
  loading: boolean;
}

const initialState: State = {
  workouts: [],
  trainers: [],
  reviews: [],
  reviewRequests: {
    sent: [],
    received: []
  },
  error: {
    errorMessage: null,
      errors: null
  },
  success: null,
  loading: false
};

export function workoutsReducer(state: State = initialState, action: WorkoutsActions.WorkoutsActions) {
  switch (action.type) {
    case WorkoutsActions.GET_WORKOUTS_START:
    case WorkoutsActions.GET_TRAINERS_START:
    case WorkoutsActions.GET_REVIEWS_START:
    case WorkoutsActions.CREATE_REVIEW_START:
    case WorkoutsActions.CREATE_REVIEW_REQUESTS_START:
    case WorkoutsActions.GET_REVIEW_REQUESTS_START:
    case WorkoutsActions.UPLOAD_YT_VIDEO_START:
      return {
        ...state,
        success: null,
        error: null,
        loading: true,
        reviews: [],
        reviewRequests: {
          sent: [],
          received: []
        },
      }
    case WorkoutsActions.CREATE_TRAINER_REVIEW_REQUEST_START:
      return {
        ...state,
        loading: true,
        success: null,
        error: null
      }
    case WorkoutsActions.CREATE_TRAINER_REVIEW_REQUEST_SUCCESS:
      state.reviewRequests.received.push(action.payload.reviewRequest);
      return {
        ...state,
        reviewRequests: state.reviewRequests,
        loading: false,
      }
    case WorkoutsActions.GET_WORKOUTS_SUCCESS:
      return {
        ...state,
        workouts: action.payload.workouts,
        loading: false,
        error: null
      }
    case WorkoutsActions.GET_TRAINERS_SUCCESS:
      return {
        ...state,
        trainers: action.payload.trainers,
        loading: false,
        error: null
      }
    case WorkoutsActions.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: action.payload.reviews,
        error: null
      }
    case WorkoutsActions.CREATE_REVIEW_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case WorkoutsActions.GET_REVIEW_REQUESTS_SUCCESS:
      return {
        ...state,
        reviewRequests: {
          sent: action.payload.reviewRequests.sent,
          received: action.payload.reviewRequests.received,
        },
        loading: false,
        error: null
      }
    case WorkoutsActions.WORKOUTS_FAIL:
      return  {
        ...state,
        loading: false,
        success: null,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        }
      }
    case WorkoutsActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    case WorkoutsActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case WorkoutsActions.CONFIRM_REVIEW_REQUEST_START:
      return {
        ...state,
        loading: true,
        success: null,
        error: null
      }
    case WorkoutsActions.CONFIRM_REVIEW_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    default:
      return state;
  }
}
