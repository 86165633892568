import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {of} from 'rxjs';
import * as AccountActions from './account.actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {BasicResponseModel} from '../../../../shared/models/basic-response.model';
import {MatSnackBar} from '@angular/material';
import {UserProfile} from '../../../../shared/models/user/user-profile';
import {Router} from '@angular/router';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error && errorRes.error.message) {
    errorMessage = errorRes.error.message;
  } else if (errorRes.error && errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message ? errorRes.error.errors.message : 'An error occurred!';
    errors = errorRes.error.errors;
  }
  return of(new AccountActions.AccountFail({errorMessage, errors}));
};

@Injectable()
export class AccountEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  @Effect()
  getProfile = this.actions$.pipe(
    ofType(AccountActions.GET_ACCOUNT_START),
    switchMap(() => {
      return this.http.post(
        environment.baseApiUrl + 'auth/me',
        {}
      ).pipe(
        map((resData: UserProfile) => {
          return new AccountActions.GetAccountSuccess({profile: resData});
        }),
        catchError(
          resError => {
            return handleError(resError);
          }
        )
      );
    })
  );
  @Effect()
  startUpdate = this.actions$.pipe(
    ofType(AccountActions.UPDATE_ACCOUNT_START),
    switchMap((action: AccountActions.UpdateAccountStart) => {
      return this.http.post<BasicResponseModel>(
        environment.baseApiUrl + 'users/update',
        action.payload.profile
      ).pipe(
        map(resData => {
          this.snackBar.open(resData.success.message, 'Close', {duration: 2500});
          return new AccountActions.UpdateAccountSuccess({profile: resData.success.data});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect({dispatch: false})
  updateSuccess = this.actions$.pipe(
    ofType(AccountActions.UPDATE_ACCOUNT_SUCCESSS),
    tap(() => {
      this.router.navigate(['account']);
    })
  );
}
