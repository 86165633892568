import {Resolve} from '@angular/router';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as AppState from '../../store/app.reducer';
import * as AuthActions from '../../authentication/store/authentication.actions';

@Injectable({providedIn: 'root'})
export class TrainingResolve implements Resolve<any> {
  constructor(
    private store: Store<AppState.AppState>
  ) {}
  resolve() {
    this.store.dispatch(new AuthActions.ProfileStart());
    return null;
  }
}
