import {User} from '../../shared/models/user/user.model';
import * as AuthenticationActions from './authentication.actions';
import {UserProfile} from '../../shared/models/user/user-profile';
import {Roles} from '../../shared/enums/roles.enum';

export interface State {
  user: User;
  profile: UserProfile;
  resetPassword: {
    isValidToken: boolean;
  };
  error: {
    errorMessage: string,
    errors: any
  };
  success: string;
  loading: boolean;
}

const initialState: State = {
  user: null,
  profile: null,
  error: null,
  success: null,
  loading: false,
  resetPassword: {
    isValidToken: false
  }
};

export function authenticationReducer(state = initialState, action: AuthenticationActions.AuthenticationActions) {
  switch (action.type) {
    case AuthenticationActions.REGISTER_START:
    case AuthenticationActions.LOGIN_START:
    case AuthenticationActions.RESET_PASSWORD_EMAIL_START:
    case AuthenticationActions.RESET_PASSWORD_START:
      return {
        ...state,
        error: null,
        success: null,
        loading: true
      };
    case AuthenticationActions.AUTHENTICATE_SUCCESS:
      const newUser = new User(
        action.payload.email,
        action.payload.roles,
        action.payload.token,
        action.payload.expirationDate,
        action.payload.id
      );
      return {
        ...state,
        loading: false,
        user: newUser
      }
    case AuthenticationActions.AUTHENTICATE_FAIL:
      return {
        ...state,
        user: null,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
        loading: false
      };
    case AuthenticationActions.LOGOUT:
      return {
        ...state,
        user: null,
        loading: false
      };
    case AuthenticationActions.PROFILE_START:
      return {
        ...state,
        loading: true,
        profile: null,
        error: null,
        success: null
      }
    case AuthenticationActions.RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message
      }
    case AuthenticationActions.CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        resetPassword: {
          isValidToken: action.payload.isTokenValid
        }
      }
    case AuthenticationActions.CHECK_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        error: {
          errorMessage: action.payload.message,
          errors: null
        },
        resetPassword: {
          isValidToken: action.payload.isTokenValid
        }
      }
    case AuthenticationActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message
      }
    case AuthenticationActions.PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.profile,
        loading: false,
        error: null,
      }
    default:
      return state;
  }
}
