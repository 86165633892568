import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as AppState from '../../store/app.reducer';
import * as AuthActions from '../../authentication/store/authentication.actions';
import * as CalendarActions from '../../pages/shared-pages/calendar/store/calendar.actions';
import * as MessagesActions from '../../pages/shared-pages/messages/store/messages.actions';
import {Roles} from '../../shared/enums/roles.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private tokenExpirationTimer: any;
  userIsTrainer = false;
  userIsStudent = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState.AppState>
  ) { }

  setLogoutTimer(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.store.dispatch(new AuthActions.Logout());
    }, expirationDuration);
  }
  clearLogoutTimer() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
      this.tokenExpirationTimer = null;
    }
  }
  logout() {
    this.clearLogoutTimer();
    this.store.dispatch(new CalendarActions.ClearStore());
    this.store.dispatch(new MessagesActions.ClearStore());
  }
  checkStoreUser() {
    this.store.select('authentication').subscribe(authState => {
      if (!authState.user) {
        this.store.dispatch(new AuthActions.AutoLogin());
      }
    });
  }
  public isTrainer(roles: Roles[]) {
    let trainer = false
    if (roles) {
      trainer = !(roles.indexOf(Roles.Trainer) === -1);
    }
    this.userIsTrainer = trainer;
    return this.userIsTrainer;
  }
  public isStudent(roles: Roles[]) {
    let student = false;
    if (roles) {
      student = !(roles.indexOf(Roles.Student) === -1);
    }
    this.userIsStudent = student;
    return this.userIsStudent;
  }
  public isAdmin(roles: Roles[]) {
    if (roles) {
      return !(roles.indexOf(Roles.Admin) === -1);
    }
    return false;
  }
}
