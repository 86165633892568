import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes, RunGuardsAndResolvers} from '@angular/router';
import {HomeComponent} from './pages/shared-pages/home/home.component';
import {Roles} from './shared/enums/roles.enum';
import {RootResolve} from './core/resolvers/root.resolve';
import {AuthenticationGuard} from './core/authentication/authentication.guard';
import {TrainingResolve} from './core/resolvers/training.resolve';
import {WorkoutsResolve} from './core/resolvers/workouts.resolve';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: {data: RootResolve},
    runGuardsAndResolvers: 'always' as RunGuardsAndResolvers,
    },
  {
    path: 'auth',
    loadChildren: './authentication/authentication.module#AuthenticationModule'
  },
  {
    path: 'trainer',
    loadChildren: './pages/trainer/trainer/trainer.module#TrainerModule',
    data: {
      roles: [Roles.Trainer, Roles.Admin]
    }
  },
  {
    path: 'student',
    loadChildren: './pages/student/student/student.module#StudentModule',
    data: {
      roles: [Roles.Student, Roles.Admin]
    }
  },
  {
    path: 'calendar',
    loadChildren: './pages/shared-pages/calendar/calendar.module#CalendarModule',
    data: {
      roles: [Roles.Trainer, Roles.Student, Roles.Admin]
    },
    runGuardsAndResolvers: 'always' as RunGuardsAndResolvers
  },
  {
    path: 'training/:id',
    canActivate: [AuthenticationGuard],
    loadChildren: './pages/shared-pages/training/training.module#TrainingModule',
    data: {
      roles: [Roles.Trainer, Roles.Student, Roles.Admin]
    },
    resolve: { data: TrainingResolve}
  },
  {
    path: 'workouts',
    canActivate: [AuthenticationGuard],
    loadChildren: './pages/shared-pages/workouts/workouts.module#WorkoutsModule',
    data: {
      roles: [Roles.Trainer, Roles.Student, Roles.Admin]
    },
    resolve: {data: WorkoutsResolve}
  },
  {
    path: 'account',
    canActivate: [AuthenticationGuard],
    loadChildren: './pages/shared-pages/account/account.module#AccountModule',
    data: {
      roles: [Roles.Trainer, Roles.Student, Roles.Admin]
    }
  },
  {
    path: 'profile',
    canActivate: [AuthenticationGuard],
    loadChildren: './pages/shared-pages/profile/profile.module#ProfileModule',
    data: {
      roles: [Roles.Trainer, Roles.Student, Roles.Admin]
    }
  },
  {
    path: 'messages',
    canActivate: [AuthenticationGuard],
    loadChildren: './pages/shared-pages/messages/messages.module#MessagesModule',
    data: {
      roles: [Roles.Trainer, Roles.Student, Roles.Admin]
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
